import { Player } from '@iheartradio/web.companion';
import { useEffect } from 'react';

import { type AppsFlyerSdk } from '~app/hooks/apps-flyer/use-apps-flyer';

export type AppsFlyerHelperComponentProps = {
  appsFlyerSdk: AppsFlyerSdk;
};

export const AppsFlyerHelper: React.FC<AppsFlyerHelperComponentProps> = ({
  appsFlyerSdk,
}: AppsFlyerHelperComponentProps) => {
  const [isFullScreen] = Player.useFullScreen();

  useEffect(() => {
    if (isFullScreen) {
      appsFlyerSdk.hideBanner();
    } else {
      appsFlyerSdk.showBanner();
    }
  }, [isFullScreen, appsFlyerSdk]);

  return null;
};
