import { Player } from '@iheartradio/web.companion';

import { playback } from '~app/playback';

export function Forward30({ disabled }: { disabled?: boolean }) {
  const player = playback.usePlayer();
  const { adBreak } = playback.useAds();

  return (
    <Player.Forward30
      data-test="forward-30-player-button"
      disabled={adBreak || disabled}
      onClick={() => player.fastForward(30)}
    />
  );
}
