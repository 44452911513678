import { Player } from '@iheartradio/web.companion';
import { isNull } from '@iheartradio/web.utilities';
import { Link as RouterLink } from '@remix-run/react';

import { playback } from '..';

export function AdsMetadata() {
  const metadata = playback.useMetadata();

  if (isNull(metadata)) {
    return null;
  }

  const { description, subtitle, title, link, iframe } = metadata.data;

  return (
    <>
      {iframe ? (
        <iframe
          frameBorder={0}
          src={iframe}
          style={{
            border: '0px',
            verticalAlign: 'bottom',
            display: 'block',
            height: '0px',
            width: '0px',
          }}
          title="ad_viewability"
        />
      ) : null}
      <Player.MetadataText>
        <Player.Subtitle>{subtitle}</Player.Subtitle>
        <Player.Title>
          {link ? (
            <Player.Link
              as={RouterLink}
              data-test="title-link"
              to={link}
              underline="hover"
            >
              {title}
            </Player.Link>
          ) : (
            title
          )}
        </Player.Title>
        <Player.Description>{description}</Player.Description>
      </Player.MetadataText>
    </>
  );
}
