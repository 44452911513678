import {
  AdChoicesIcon,
  FacebookIcon,
  Flex,
  InstagramIcon,
  Separator,
  Spacer,
  Text,
  TikTokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@iheartradio/web.companion';

import { FooterBackToIheart } from './footer-back-to-iheart';
import { FooterLink } from './footer-link';
import { FooterSocial } from './footer-social';

export const Footer = () => {
  const copyrightText = (
    <Text
      color={{ light: '$gray-400', dark: '$gray-300' }}
      kind="caption-4"
      paddingRight="$32"
    >
      © {new Date().getFullYear()} iHeartMedia, Inc.
    </Text>
  );

  return (
    <Flex
      alignSelf="flex-end"
      as="footer"
      data-test="footer"
      direction="column"
      width="100%"
    >
      <Spacer
        bottom="$32"
        top="$40"
        width="100%"
        x={{
          '@initial': '$16',
          '@large': '$24',
          '@xlarge': '$32',
        }}
      >
        <FooterBackToIheart />
        <Separator
          backgroundColor={{ light: '$gray-200', dark: '$gray-500' }}
        />
        <Flex
          flexDirection={{
            '@initial': 'column',
            '@large': 'row',
          }}
          flexWrap="wrap"
          left="$2"
          right="$16"
        >
          <Flex as="ul" paddingRight="$32" paddingTop="$16">
            <FooterSocial
              icon={FacebookIcon}
              link="https://www.facebook.com/iheartradio"
              name="facebook"
            />
            <FooterSocial
              icon={TwitterIcon}
              link="https://twitter.com/iHeartRadio"
              name="twitter"
            />
            <FooterSocial
              icon={InstagramIcon}
              link="https://www.instagram.com/iHeartRadio/"
              name="instagram"
            />
            <FooterSocial
              icon={TikTokIcon}
              link="https://www.tiktok.com/@iheartradio?lang=en"
              name="tiktok"
            />
            <FooterSocial
              icon={YoutubeIcon}
              link="https://www.youtube.com/user/iHeartRadio"
              name="youtube"
            />
          </Flex>
          <Flex flexWrap="wrap">
            <Flex
              alignItems="center"
              display={{
                '@initial': 'none',
                '@medium': 'flex',
                '@xlarge': 'inline-flex',
              }}
              paddingTop="$16"
            >
              {copyrightText}
            </Flex>
            <Flex
              alignItems="center"
              as="ul"
              columnGap="$24"
              flexWrap="wrap"
              paddingTop="$16"
              rowGap="$8"
            >
              <FooterLink link="https://www.iheart.com/help">Help</FooterLink>
              <FooterLink link="https://www.iheart.com/privacy">
                Privacy Policy
              </FooterLink>
              <FooterLink link="https://www.iheart.com/terms">
                Terms of Use
              </FooterLink>
              <FooterLink link="https://www.iheart.com/optout">
                Do not sell or share my personal information
              </FooterLink>
              <FooterLink link="https://www.iheart.com/adchoices">
                AdChoices
                <Flex paddingLeft="$4">
                  <AdChoicesIcon
                    fill={{ light: 'gray-450', dark: 'gray-250' }}
                    size="18"
                  />
                </Flex>
              </FooterLink>
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            display={{ '@medium': 'none' }}
            paddingTop="$16"
          >
            {copyrightText}
          </Flex>
        </Flex>
      </Spacer>
    </Flex>
  );
};
