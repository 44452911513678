import { type Params, useLocation, useMatches } from '@remix-run/react';
import type { LinkDescriptor, SerializeFrom } from '@remix-run/server-runtime';
import { type AppData } from '@remix-run/server-runtime/dist/data';

type Location = ReturnType<typeof useLocation>;
type Matches = ReturnType<typeof useMatches>;

export type HandleConventionArguments<Data = AppData> = {
  id: string;
  data: Data;
  location: Location;
  params: Params;
  matches: Matches;
  parentsData: AppData[];
};

/**
 * @deprecated Use new V2_MetaFunction instead
 * @see https://remix.run/docs/en/main/route/meta-v2
 */
export interface DynamicLinksFunction<T = AppData> {
  (args: HandleConventionArguments<SerializeFrom<T>>): LinkDescriptor[];
}

/**
 * @deprecated Use new V2_MetaFunction instead
 * @see https://remix.run/docs/en/main/route/meta-v2
 */
export function DynamicLinks() {
  const location = useLocation();

  const links: LinkDescriptor[] = useMatches().flatMap(
    (match, index, matches) => {
      // @ts-expect-error - Copy/paste from "remix-utils"
      const fn = match.handle?.dynamicLinks as DynamicLinksFunction | undefined;
      if (typeof fn !== 'function') return [];
      const result = fn({
        id: match.id,
        data: match.data,
        params: match.params,
        parentsData: matches.slice(0, index).map(match => match.data),
        location,
        matches,
      });
      if (Array.isArray(result)) return result;
      return [];
    },
  );

  return (
    <>
      {links.map(link => (
        <link {...link} key={link.integrity || JSON.stringify(link)} />
      ))}
    </>
  );
}
