import {
  Box,
  Button,
  CancelFilledIcon,
  Dialog,
  Flex,
  LogotypeEarlyAccess,
  Text,
} from '@iheartradio/web.companion';
import { useFetcher } from '@remix-run/react';
import { type ReactNode, useEffect, useState } from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import { type UserEducationModalAction } from '~app/routes/close-user-education-modal';

type UserEducationDialogProps = {
  showDialog: boolean;
};

const DialogListItem = ({
  children,
  Icon = <CancelFilledIcon size={16} />,
}: {
  children: ReactNode;
  Icon?: ReactNode;
}) => (
  <Flex alignItems="center" as="li" gap="$8">
    <Box>{Icon}</Box>
    <Text as="p" kind="body-3">
      {children}
    </Text>
  </Flex>
);

export const UserEducationDialog = ({
  showDialog = false,
}: UserEducationDialogProps) => {
  const [open, setOpen] = useState(showDialog);

  const tryNowFetcher = useFetcher<UserEducationModalAction>();
  const doNotShowAgainFetcher = useFetcher<UserEducationModalAction>();

  const isSubmitting = tryNowFetcher.state === 'submitting';
  const isLoading = tryNowFetcher.state === 'loading';
  const isDone = tryNowFetcher.state === 'idle' && tryNowFetcher.data != null;

  const isSubmittingDoNotShow = doNotShowAgainFetcher.state === 'submitting';
  const isLoadingDoNotShow = doNotShowAgainFetcher.state === 'loading';
  const isDoneDoNotShow =
    doNotShowAgainFetcher.state === 'idle' &&
    doNotShowAgainFetcher.data != null;

  useEffect(() => {
    if (isDone || isDoneDoNotShow) {
      setOpen(false);
    }
  }, [isDone, isDoneDoNotShow]);

  return (
    <ClientOnly>
      {() => (
        <Dialog.Root onOpenChange={setOpen} open={open} showClose={false}>
          <Dialog.Metadata>
            <LogotypeEarlyAccess
              aria-label="iHeart Header Logotype"
              media="tablet"
              size={40}
            />
            <Dialog.Title>Welcome to try the new iHeart site! </Dialog.Title>
            <Dialog.Body>
              This is a limited access site to gather feedback from a select
              group of listeners like yourself. These features are unavailable
              but coming soon:
            </Dialog.Body>
            <Flex as="ul" flexDirection="column" gap="$16">
              <DialogListItem>
                Following live and artist stations, podcasts, and playlists to
                save to your Library
              </DialogListItem>
              <DialogListItem>Thumbing up or down a song</DialogListItem>
              <DialogListItem>Sharing content on social media</DialogListItem>
            </Flex>
            <Dialog.ButtonContainer>
              <tryNowFetcher.Form
                action="/close-user-education-modal"
                method="POST"
              >
                <Button
                  color="red"
                  css={{
                    light: {
                      '&:focus-visible': { outline: 'none' },
                    },
                    dark: {
                      '&:focus-visible': { outline: 'none' },
                    },
                  }}
                  disabled={isSubmitting || isLoading}
                  kind="primary"
                  size={{ '@initial': 'small', '@medium': 'large' }}
                  type="submit"
                >
                  Try it now
                </Button>
              </tryNowFetcher.Form>
              <doNotShowAgainFetcher.Form
                action="/close-user-education-modal"
                method="POST"
              >
                <input name="dontshowagain" type="hidden" value="true" />
                <Button
                  color={{ light: 'gray', dark: 'white' }}
                  css={{
                    light: {
                      '&:focus-visible': { outline: 'none' },
                    },
                    dark: {
                      '&:focus-visible': { outline: 'none' },
                    },
                  }}
                  disabled={isSubmittingDoNotShow || isLoadingDoNotShow}
                  kind="tertiary"
                  size={{ '@initial': 'small', '@medium': 'large' }}
                  type="submit"
                >
                  Got it! Don’t show again
                </Button>
              </doNotShowAgainFetcher.Form>
            </Dialog.ButtonContainer>
          </Dialog.Metadata>
        </Dialog.Root>
      )}
    </ClientOnly>
  );
};
