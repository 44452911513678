import { Player } from '@iheartradio/web.companion';

import { playback } from '~app/playback';

export function Volume() {
  const player = playback.usePlayer();
  const { muted, volume } = playback.useState();

  return (
    <Player.Volume
      muted={muted}
      onChange={player.setVolume}
      onMute={() => player.setMute()}
      volume={volume}
    />
  );
}
