import { media, Player, useMediaQuery } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';
import { playback } from '~app/playback';
import { isPremiumUser } from '~app/utilities/user';

export function Time() {
  const [isFullScreen] = Player.useFullScreen();
  const isLarge = useMediaQuery(media.large);
  const player = playback.usePlayer();
  const { adBreak } = playback.useAds();
  const time = playback.useTime();
  const isPremium = isPremiumUser(useUser());
  const { station, status } = playback.useState();
  const isLiveStation = station?.type === Playback.StationType.Live;
  const isPodcast = station?.type === Playback.StationType.Podcast;

  return (
    <Player.Time
      // Only show LIVE badge on live stations (this replaces the player progress bar in the UI)
      badge={isLiveStation && !adBreak ? Playback.StationType.Live : undefined}
      // We only disable progress bar if it's a live station or if playback is not playing or paused
      disabled={
        (isLiveStation && !adBreak) || status === Playback.Status.Buffering
      }
      duration={time.duration}
      // If you are an AA account, or content is a podcast - you can scrub progress bar on FSP and large+ breakpoints,
      // Otherwise (any other user type), you can not scrub,
      // Regardless, the spec is that any user can not scrub progress bar on mobile breakpoints due to the design, justifying to the use of `isLarge`.
      interactive={isPremium || isPodcast ? isFullScreen || isLarge : false}
      onChange={player.seek}
      // Position is the placement of the scrub thumb on the progress bar,
      // since live stations don't have a progress bar, we set position to 0
      position={isLiveStation && !adBreak ? 0 : time.position}
    />
  );
}
