import { eventType } from '@iheartradio/web.analytics';
import { loadScript, waitUntil } from '@iheartradio/web.utilities';
import { useEffect } from 'react';

import { useAnalytics } from '~app/analytics';
import { useConfig } from '~app/contexts/config';
import { useUser } from '~app/contexts/user';

type BeaconPayload = {
  c1: '2';
  c2: string;
  cs_xi?: string;
  cs_ucfr?: '0' | '1';
  options?: {
    enableFirstPartyCookie: boolean;
    bypassUserConsentRequirementFor1PCookie: boolean;
  };
};

declare global {
  interface Window {
    COMSCORE?: {
      beacon: (payload: BeaconPayload) => void;
    };
  }
}

export function Comscore() {
  const analytics = useAnalytics();
  const config = useConfig();
  const user = useUser();

  const comscoreId = config.sdks.comScore?.customerId;

  useEffect(() => {
    if (comscoreId) {
      const unsubscribe = analytics.subscribe({
        async initialize() {
          if (window.COMSCORE) return;

          await loadScript(
            `https://sb.scorecardresearch.com/cs/${comscoreId}/beacon.js`,
            { async: true },
          );

          await waitUntil(() => !!window.COMSCORE);
        },

        async track(data) {
          if (data.type !== eventType.enum.PageView) {
            return;
          }

          const payload: BeaconPayload = {
            c1: '2',
            c2: comscoreId,
            cs_xi: String(user?.profileId),
            cs_ucfr: user?.privacy?.hasOptedOut ? '0' : '1',
            options: {
              enableFirstPartyCookie: true,
              bypassUserConsentRequirementFor1PCookie: user?.privacy
                ?.hasOptedOut
                ? false
                : true,
            },
          };

          window.COMSCORE?.beacon?.(payload);
        },
      });

      return unsubscribe;
    } else {
      return () => {};
    }
  }, [
    analytics,
    config.sdks.comScore?.customerId,
    user?.privacy?.hasOptedOut,
    user?.profileId,
    comscoreId,
  ]);

  return null;
}
