import { useEffect } from 'react';

import { useAnalytics } from '~app/analytics';
import { sendIglooEvent } from '~app/analytics/send-igloo-event';
import { useConfig } from '~app/contexts/config';

export function Igloo() {
  const config = useConfig();
  const analytics = useAnalytics();

  useEffect(() => {
    const unsubscribe = analytics.subscribe({
      async track(payload) {
        if (!config.urls.iglooUrl) {
          console.error(`'config.urls.iglooUrl' is not set`);
          return;
        }

        return await sendIglooEvent(config.urls.iglooUrl, payload);
      },
    });
    return unsubscribe;
  }, [analytics, config.urls?.iglooUrl]);

  return null;
}
