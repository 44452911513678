import {
  type BoxProps,
  Box,
  Flex,
  keyframes,
  Link,
  media,
  Player,
} from '@iheartradio/web.companion';
import { forwardRef, useEffect } from 'react';

import { useConfig } from '~app/contexts/config';

import { playback } from './playback';

export const VideoPlayer = forwardRef(function VideoPlayer(
  _props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const config = useConfig();
  const [isFullScreen] = Player.useFullScreen();
  const { adBreak, type } = playback.useAds();
  const showPlayer = adBreak && type === 'video';

  const subscribeLink = `${config.urls.account}/subscribe`;

  useEffect(() => {
    const isMobile = navigator.userAgentData?.mobile ?? false;
    const body = document.querySelector('body');

    if (showPlayer && isMobile && body) {
      body.style.overflow = 'hidden';
    }

    return () => {
      if (isMobile) {
        body!.removeAttribute('style');
      }
    };
  }, [showPlayer]);

  const containerStyle: BoxProps['css'] = {
    aspectRatio: '4 / 3',
    backgroundColor: showPlayer ? 'black' : 'transparent',
    display: 'none',
    placeItems: 'center',
    position: 'fixed',
    width: '63.2rem',
    maxWidth: '100vw',
    maxHeight: '75vh',
    bottom: 0,
    left: 0,
    transition: 'top 350ms ease, transform 350ms ease',

    '& .jwplayer': {
      display: 'none',
    },

    '& .jw-wrapper': {
      backgroundColor: 'transparent !important',
    },

    '@large': {
      height: '47.4rem',
      transition: 'top 350ms ease, transform 350ms ease',
      transformOrigin: 'bottom left',
      zIndex: '$0',
      transform: 'translate(0, 0)',
      '&[data-scrolled="1"]': {
        ...(!isFullScreen
          ? {
              display: showPlayer ? 'flex' : 'none',
              transform: 'scale3d(0.5, 0.5, 1)',
            }
          : {}),
        transition: 'transform 350ms ease',
      },
      '& .jw-wrapper': {
        backgroundColor: 'transparent !important',
      },
      '&:not([data-scrolled="0"]):hover': {
        ...(!isFullScreen
          ? {
              transform: 'scale3d(1,1,1)',
              transition: 'transform 350ms ease',
              left: '0rem',
            }
          : {}),
      },
      ...(isFullScreen
        ? {
            transform: 'translate(calc(50dvw - 50%), calc(-100dvh + 63.4rem))',
            transition: 'transform 350ms ease',
            zIndex: '$10',
          }
        : {}),
    },

    '@medium': {
      height: '47.4rem',
      transformOrigin: 'top center',
      transition: 'transform 350ms ease',
      ...(showPlayer
        ? {
            transform: 'translate(calc(50dvw - 50%), calc(23.7rem + -50dvh))',
          }
        : {}),
      ...(isFullScreen
        ? {
            transform:
              'translate(calc(50dvw - 50%), calc(-100dvh + 53.4rem)) scale3d(0.6, 0.6, 1)',
          }
        : {}),
    },

    '@small': {
      height: '47.4rem',
      transition: 'transform 350ms ease',
      ...(showPlayer
        ? {
            transform: 'translate(0, calc(-50dvh + 50%))',
          }
        : {}),
      ...(isFullScreen
        ? {
            transform: 'translate(0, calc(53.4rem - 100dvh))',
          }
        : {}),
    },

    '@xsmall': {
      height: '23.7rem',
      transition: 'transform 350ms ease',
      ...(showPlayer
        ? {
            transform: 'translate(0, calc(-50dvh + 50%))',
          }
        : {}),
      ...(isFullScreen
        ? {
            transform: 'translate(0, calc(29.7rem - 100dvh))',
          }
        : {}),
    },
    ...(showPlayer
      ? {
          '& .jwplayer': {
            aspectRatio: '16 / 9',
            display: 'block',
            overflow: 'visible',
          },
        }
      : {}),
  };

  const transparentToFortyPercent = keyframes({
    '0%': { backgroundColor: 'rgba(0,0,0,0)' },
    '100%': { backgroundColor: 'rgba(0,0,0,0.4)' },
  });

  return (
    <Box
      animationDuration="350ms"
      animationIterationCount="1"
      animationName={`${transparentToFortyPercent}`}
      backgroundSize="cover"
      bottom={0}
      css={{
        backgroundColor: isFullScreen ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.4)',
        '&:has(div[data-scrolled="1"])': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
        '&:has(div[data-scrolled="1"]:hover)': {
          backgroundColor: 'rgba(0,0,0,0.4);',
        },
      }}
      data-test="video-player-positioning-context"
      display={showPlayer ? 'block' : 'none'}
      height={{
        [`@container ${media.xsmall}`]: '100dvh',
        [`@container ${media.large}`]: 'calc(100dvh - 8.8rem)',
      }}
      left={0}
      position="absolute"
      right={0}
      top={0}
      transform={{
        [`@container ${media.xsmall}`]:
          'translateY(calc((100dvh - 12.4rem) * -1))',
        [`@container ${media.large}`]:
          'translateY(calc((100dvh - 8.8rem) * -1))',
      }}
      transition="transform 0ms, background-color 350ms ease"
      width="100%"
      zIndex={{
        [`@container ${media.xsmall}`]: '$10',
        [`@container ${media.large}`]: '$1',
      }}
    >
      <Flex
        css={containerStyle}
        data-scrolled="0"
        flexDirection="column"
        gap="0"
        id="iheart-player-container"
        justifyContent="center"
        ref={ref}
      >
        <Box
          css={{
            aspectRatio: '16 / 9',
          }}
          id="jw-player"
        ></Box>
        <Flex
          css={{
            ...(showPlayer ? {} : { display: 'none' }),
            marginRight: '4rem',
            marginTop: '2rem',
            marginBottom: '-2rem',
            display: 'flex',
          }}
          flexDirection="row"
          justifyContent="flex-end"
          width="100%"
        >
          <Link
            color="secondary"
            href={subscribeLink}
            kind={{ '@initial': 'subtitle-4', '@xsmall': 'subtitle-5' }}
            target="_blank"
            underline="hover"
          >
            Try Ad-free Experience
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
});

VideoPlayer.displayName = 'VideoPlayer';
