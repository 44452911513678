import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isNull } from '@iheartradio/web.utilities';

import { playback } from '~app/playback';

import { AdsMetadata } from './ads-metadata';
import { AlbumMetadata } from './album-metadata';
import { ArtistMetadata } from './artist-metadata';
import { FavoritesMetadata } from './favorites-metadata';
import { LiveMetadata } from './live-metadata';
import { PlaylistMetadata } from './playlist-metadata';
import { PodcastMetadata } from './podcast-metadata';
import { TopSongsMetadata } from './top-songs-metadata';

const meta = {
  [Playback.StationType.Album]: AlbumMetadata,
  [Playback.StationType.Artist]: ArtistMetadata,
  [Playback.StationType.Favorites]: FavoritesMetadata,
  [Playback.StationType.Live]: LiveMetadata,
  [Playback.StationType.Playlist]: PlaylistMetadata,
  [Playback.StationType.PlaylistRadio]: PlaylistMetadata,
  [Playback.StationType.Podcast]: PodcastMetadata,
  [Playback.StationType.TopSongs]: TopSongsMetadata,
  Ad: AdsMetadata,
} as const;

export function Metadata() {
  const metadata = playback.useMetadata();
  const state = playback.useState();
  const { adBreak } = playback.useAds();

  if (isNull(state.station)) {
    return null;
  }

  const Meta = meta[adBreak ? 'Ad' : state.station.type];

  return (
    <Player.Metadata data-test="player-metadata">
      <Player.Artwork
        alt="artwork"
        lazy={adBreak ? false : true}
        src={metadata?.data?.image}
      />
      <Meta />
    </Player.Metadata>
  );
}
