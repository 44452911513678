import { Player } from '@iheartradio/web.companion';
import { isNotBlank, isNull } from '@iheartradio/web.utilities';
import { Link as RouterLink } from '@remix-run/react';

import { useIsTouch } from '~app/hooks/use-is-touch';
import { playback } from '~app/playback';
import { buildPodcastEpisodeUrl, buildPodcastUrl } from '~app/utilities/urls';

export function PodcastMetadata() {
  const metadata = playback.useMetadata();
  const { station } = playback.useState();
  const { isTouch } = useIsTouch();

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const { description, id, podcastId, podcastSlug, subtitle } = metadata.data;

  const episodeUrl =
    isNotBlank(podcastId) &&
    isNotBlank(podcastSlug) &&
    isNotBlank(id) &&
    isNotBlank(description)
      ? buildPodcastEpisodeUrl({
          podcastId,
          podcastSlug,
          episodeId: id,
          episodeName: description,
        })
      : undefined;

  const podcastUrl =
    isNotBlank(podcastId) && isNotBlank(podcastSlug)
      ? buildPodcastUrl({ podcastId, slug: podcastSlug })
      : undefined;

  return (
    <Player.MetadataText>
      <Player.Title>
        {isTouch || !podcastUrl ? (
          subtitle
        ) : (
          <Player.Link
            as={RouterLink}
            data-test="subtitle-link"
            to={episodeUrl ?? podcastUrl}
            underline="hover"
          >
            {subtitle}
          </Player.Link>
        )}
      </Player.Title>
      <Player.Description>
        {isTouch || !episodeUrl ? (
          description
        ) : (
          <Player.Link
            as={RouterLink}
            data-test="description-link"
            to={podcastUrl ?? episodeUrl}
            underline="hover"
          >
            {description}
          </Player.Link>
        )}
      </Player.Description>
    </Player.MetadataText>
  );
}
