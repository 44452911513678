import { type Icon, Box, Link } from '@iheartradio/web.companion';

export type FooterSocialProps = {
  name: string;
  link: string;
  icon: typeof Icon;
};

export const FooterSocial = ({
  name,
  link,
  icon: IconComponent,
}: FooterSocialProps) => (
  <Box as="li" listStyle="none" paddingRight="$4">
    <Link
      css={{
        display: 'inline-block',
        borderRadius: '$999',
        padding: '$4',
        fontSize: 0,
        transition: 'all 200ms ease',
        outline: 'none',
        hover: {
          light: { backgroundColor: '$gray-300' },
          dark: { backgroundColor: '$gray-400' },
        },
        pressed: {
          light: { backgroundColor: '$gray-200' },
          dark: { backgroundColor: '$gray-500' },
        },
        focus: {
          outline: 'none',
        },
      }}
      data-test={name}
      href={link}
      rel="noreferrer"
      target="_blank"
    >
      <IconComponent
        fill={{ light: 'gray-450', dark: 'brand-white' }}
        size={24}
      />
    </Link>
  </Box>
);
