import { Box, Flex } from '@iheartradio/web.companion';
import type React from 'react';
import { useMemo } from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import { Footer } from '~app/components/footer';
import { Nav } from '~app/components/navigation';
import { UserEducationDialog } from '~app/components/user-education-dialog';
import { AppsFlyerStyles } from '~app/hooks/apps-flyer/apps-flyer-styles';
import {
  type AppsFlyerOffset,
  type AppsFlyerOffsetRem,
  useAppsFlyer,
} from '~app/hooks/apps-flyer/use-apps-flyer';
import { Player } from '~app/playback';

export type LayoutProps = {
  children: React.ReactNode;
  showUserEducationDialog?: boolean;
};

export const bannerContainerId = 'apps-flyer-container';

function getMainPaddingTop(offset: AppsFlyerOffset): AppsFlyerOffsetRem {
  const mainBasePaddingTop = 4.8;
  let additionalValue = 0;

  if (offset && offset.endsWith('rem')) {
    additionalValue = Number.parseFloat(offset);
  }

  const totalValue = mainBasePaddingTop + additionalValue;
  return offset ? `${totalValue}rem` : `${mainBasePaddingTop}rem`;
}

export function Layout({
  children,
  showUserEducationDialog = false,
}: LayoutProps) {
  const { bannerRef, appsFlyerOffset, appsFlyerSdk } = useAppsFlyer({
    sdkScriptId: 'apps-flyer-script-nav',
    bannerContainerId,
    view: {
      stacked: true,
    },
  });

  const mainPaddingTop = useMemo(
    () => getMainPaddingTop(appsFlyerOffset),
    [appsFlyerOffset],
  );

  return (
    <>
      <Box id={bannerContainerId} ref={bannerRef} style={{ width: '100%' }}>
        <ClientOnly>{() => <AppsFlyerStyles />}</ClientOnly>
      </Box>
      <UserEducationDialog showDialog={showUserEducationDialog} />
      <Nav appsFlyerOffset={appsFlyerOffset} />
      <Player appsFlyerSdk={appsFlyerSdk} />
      <Flex
        as="main"
        css={{
          alignItems: 'center',

          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100dvh',

          '@xsmall': {
            paddingTop: mainPaddingTop,
            paddingBottom: '12.4rem',
            paddingLeft: 0,
          },

          '@large': {
            paddingTop: 0,
            paddingBottom: '8.8rem',
            paddingLeft: '31.6rem',
          },

          light: {
            backgroundColor: '$gray-100',
          },
          dark: {
            backgroundColor: '$brand-black',
          },
        }}
        data-test="main"
        id="main"
      >
        <Flex
          css={{
            flexGrow: 1,
            flexDirection: 'column',
            width: '100%',
            '& > *': {
              flexGrow: 1,
            },
          }}
        >
          <Box flexGrow={0} height="1px" id="scroll-target" width="100%" />
          {children}
        </Flex>
        <Box alignSelf="stretch">
          <Footer />
        </Box>
      </Flex>
    </>
  );
}
