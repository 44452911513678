import { Player } from '@iheartradio/web.companion';
import { isNull } from '@iheartradio/web.utilities';
import { Link as RouterLink } from '@remix-run/react';

import { useIsTouch } from '~app/hooks/use-is-touch';
import { playback } from '~app/playback';
import {
  buildArtistUrl,
  buildPlaylistUrl,
  buildSongUrl,
} from '~app/utilities/urls';

import { AddToPlaylist } from '../controls/add-to-playlist';

export function PlaylistMetadata() {
  const metadata = playback.useMetadata();
  const { station } = playback.useState();
  const { isTouch } = useIsTouch();

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const { artistId, description, id, subtitle, title } = metadata.data;

  const [userId, playlistId] = String(station.id).split('::');

  return (
    <>
      <Player.MetadataText>
        <Player.Subtitle>
          {isTouch ? (
            subtitle
          ) : (
            <Player.Link
              as={RouterLink}
              data-test="subtitle-link"
              to={buildPlaylistUrl({
                name: subtitle ?? '',
                userId,
                id: playlistId,
              })}
              underline="hover"
            >
              {subtitle}
            </Player.Link>
          )}
        </Player.Subtitle>
        <Player.Title>
          {!isTouch && id && artistId ? (
            <Player.Link
              as={RouterLink}
              data-test="title-link"
              to={buildSongUrl({
                artist: { id: artistId, name: title ?? '' },
                track: { id, name: subtitle ?? '' },
              })}
              underline="hover"
            >
              {title}
            </Player.Link>
          ) : (
            title
          )}
        </Player.Title>
        <Player.Description>
          {!isTouch && artistId ? (
            <Player.Link
              as={RouterLink}
              data-test="description-link"
              to={buildArtistUrl({
                id: artistId,
                name: description ?? '',
              })}
              underline="hover"
            >
              {description}
            </Player.Link>
          ) : (
            description
          )}
        </Player.Description>
      </Player.MetadataText>
      {Number(id) > 0 && <AddToPlaylist />}
    </>
  );
}
