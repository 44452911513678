import { Link, Text } from '@iheartradio/web.companion';
import { NavLink } from '@remix-run/react';
import { type ReactNode } from 'react';

type FooterLinkProps = {
  link: string;
  target?: string;
  children?: ReactNode;
};

export const FooterLink = ({ link, children }: FooterLinkProps) => {
  return (
    <Text as="li" kind="caption-4" listStyle="none">
      <Link
        as={NavLink}
        color="secondary"
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
        target="_blank"
        to={link}
        underline="hover"
      >
        {children}
      </Link>
    </Text>
  );
};
