import {
  getResponsiveImgAttributes,
  MediaServerURL,
} from '@iheartradio/web.assets';
import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import {
  compare,
  isNotBlank,
  isNull,
  isString,
} from '@iheartradio/web.utilities';
import { type useFetcher, Link as RouterLink } from '@remix-run/react';
import { memo, useMemo } from 'react';

import { CardCarousel } from '~app/components/card-carousel';
import { ContentCard } from '~app/components/content-card/content-card';
import { Play } from '~app/playback';
import { type PlayerContentTypeRecommendationsLoader } from '~app/routes/api.v1.player.$contentType.$stationId.recommendations';
import {
  buildAlbumUrl,
  buildArtistTopSongsUrl,
  buildArtistUrl,
  buildLiveUrl,
  buildPlaylistUrl,
  buildPodcastUrl,
} from '~app/utilities/urls';

import { Actions } from './actions';
import { ControlSet } from './control-set';
import { Time } from './controls/time';
import { getContentTitle, getSlideDataFromStation } from './helpers';
import { Metadata } from './metadata';
import { type StationRec } from './types';

function Slide({ station }: { station: StationRec }) {
  const {
    stationId,
    stationDescription,
    stationTitle,
    imageUrl,
    imageButtonJSX,
  } = getSlideDataFromStation(station);

  const { playing } = Play.usePlay({
    id: Number(stationId),
    context: 0,
    type: station.stationType,
  });

  const { src, srcSet } = getResponsiveImgAttributes(imageUrl, {
    width: 350,
    ratio: [1, 1],
  });

  return (
    <CardCarousel.Slide key={stationId}>
      <Player.Link as={RouterLink} to={station.url ?? '/'}>
        <ContentCard
          active={playing}
          image={
            <ContentCard.Image
              alt={stationTitle ?? ''}
              borderRadius={
                station.stationType === Playback.StationType.Artist
                  ? '$999'
                  : 'inherit'
              }
              src={src}
              srcSet={srcSet}
            />
          }
          imageButton={imageButtonJSX}
          subtitle={stationDescription ?? ''}
          title={stationTitle ?? ''}
        />
      </Player.Link>
    </CardCarousel.Slide>
  );
}

const getMetadata = ({
  metadata,
  station,
}: {
  metadata: Exclude<Playback.Metadata, null>;
  station: Exclude<Playback.Station, null>;
}) => {
  const {
    artistId,
    albumId,
    albumName,
    id,
    image,
    name,
    description,
    subtitle,
    podcastId,
    podcastSlug,
    title,
  } = metadata.data;

  const link = (({ stationType }) => {
    switch (stationType) {
      case Playback.StationType.Album: {
        if (artistId && title && albumId && albumName) {
          return buildAlbumUrl({
            artist: { id: artistId, name: title },
            album: { id: albumId, name: albumName },
          });
        }
        break;
      }
      case Playback.StationType.Artist: {
        if (artistId && title) {
          return buildArtistUrl({ id: artistId, name: title });
        }
        break;
      }
      case Playback.StationType.TopSongs: {
        if (artistId) {
          return buildArtistTopSongsUrl({ id: artistId, name: title ?? '' });
        }
        break;
      }
      case Playback.StationType.Live: {
        if (id && name) {
          return buildLiveUrl({ name, id });
        }
        break;
      }
      case Playback.StationType.Podcast: {
        if (podcastId && podcastSlug) {
          return buildPodcastUrl({ podcastId, slug: podcastSlug });
        }
        break;
      }
      case Playback.StationType.Playlist:
      case Playback.StationType.PlaylistRadio: {
        const [playlistUserId, playlistId] = isString(id) ? id.split('::') : [];
        if (playlistId && playlistUserId) {
          return buildPlaylistUrl({
            name: title ?? '',
            userId: playlistUserId,
            id: playlistId,
          });
        }
        break;
      }
    }
  })({ stationType: station.type });

  const stationTitle =
    station.type === Playback.StationType.Podcast ? description : subtitle;

  return {
    link,
    stationTitle,
    image,
  };
};

export type FullScreenProps = {
  metadata: Playback.Metadata;
  station: Playback.Station;
  adBreak: boolean;
  type: Playback.Ads['type'];
  carouselData?:
    | ReturnType<
        typeof useFetcher<PlayerContentTypeRecommendationsLoader>
      >['data']
    | undefined;
  isFullScreen: boolean;
  companion: JSX.Element | null;
};

export const FullScreen = memo(
  function FullScreen({
    metadata,
    station,
    adBreak,
    type,
    carouselData,
    isFullScreen,
    companion,
  }: FullScreenProps) {
    const recsCarousel = useMemo(() => {
      return isNotBlank(carouselData?.carousel?.data) &&
        !isNull(station?.type) ? (
        <CardCarousel
          buttons={!(adBreak && type === 'video')}
          key={`recs-${station.id}`}
          kind="content"
          onChange={() => carouselData}
          title={getContentTitle(station.type)}
        >
          {carouselData.carousel.data.map(rec => {
            return rec && <Slide key={rec.id} station={rec} />;
          })}
        </CardCarousel>
      ) : null;
    }, [carouselData, station?.type, station.id, adBreak, type]);

    if (isNull(metadata) || isNull(station)) {
      return null;
    }

    const { link, stationTitle, image } = getMetadata({ metadata, station });

    return (
      <Player.FullScreen
        actions={<Actions />}
        adBreak={adBreak}
        companion={companion}
        controls={<ControlSet isFullScreen={isFullScreen} />}
        header={
          isNotBlank(link) ? (
            <Player.Link as={RouterLink} to={link} underline="hover">
              {stationTitle ?? ''}
            </Player.Link>
          ) : (
            stationTitle
          )
        }
        imageSrc={
          image
            ? MediaServerURL.fromURL(image)
                .ratio(1, 1)
                .scale(500)
                .quality(75)
                .toString()
            : ''
        }
        isFullScreen={isFullScreen}
        metadata={<Metadata />}
        time={<Time />}
      >
        {recsCarousel}
      </Player.FullScreen>
    );
  },
  (previousProps, nextProps) => {
    return compare(previousProps, nextProps);
  },
);
