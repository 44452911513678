import * as Analytics from '@iheartradio/web.analytics';

import { useAnalytics } from './create-analytics';

export function useAppOpenClose() {
  const analytics = useAnalytics();

  const onAppOpen = ({
    initializationTime,
    appVersion,
  }: {
    initializationTime: number;
    appVersion: string;
  }) => {
    analytics.track({
      type: Analytics.eventType.enum.AppOpen,
      data: {
        remote: {
          location: `web|listen|${appVersion}`,
        },
        session: {
          initializationTime,
        },
      },
    });
  };
  return { onAppOpen };
}
